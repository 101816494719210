import React from 'react';
import './Roadmap.css';

const Roadmap = () => {
  return (
    <div className="roadmap">
      <h1 className="roadmap-title">ROADMAP</h1>
      <div className="roadmap-container">
        <div className="roadmap-phase">
          <h2 className="phase-title">
            PHASE 1 <span className="status completed">COMPLETED</span>
          </h2>
          <h3 className="phase-time">Foundation and Development (Q1 2024)</h3>
          <ul className="phase-goals">
            <li><strong>Core Platform Development:</strong> Complete the core architecture of our blockchain-based asset management platform.</li>
            <li><strong>Beta Testing:</strong> Launch a closed beta to gather initial feedback and refine user interfaces.</li>
            <li><strong>Strategic Alliances:</strong> Forge key partnerships to broaden technological and market reach.</li>
          </ul>
        </div>
        <div className="roadmap-phase current">
          <h2 className="phase-title">PHASE 2</h2>
          <h3 className="phase-time">Public Launch and Expansion (Q2-Q3 2024)</h3>
          <ul className="phase-goals">
            <li><strong>Public Platform Release:</strong> Launch the platform publicly with full features including fractional ownership and advanced verification tools.</li>
            <li><strong>Mobile App Development:</strong> Start development on a mobile app to provide users seamless access to our services on-the-go.</li>
            <li><strong>Market Expansion:</strong> Target new geographic and demographic user segments.</li>
          </ul>
        </div>
        <div className="roadmap-phase">
          <h2 className="phase-title">PHASE 3</h2>
          <h3 className="phase-time">Diversification and Mobile Integration (Q4 2024 - Q1 2025)</h3>
          <ul className="phase-goals">
            <li><strong>Mobile App Launch:</strong> Release the mobile app with full integration to our platform services including real-time notifications, asset management, and DeFi opportunities.</li>
            <li><strong>DeFi Tools and Royalties:</strong> Implement DeFi features for asset financing and introduce a royalty system for contributors.</li>
            <li><strong>Enhanced Community Engagement:</strong> Boost community interactions through mobile-driven campaigns and features.</li>
          </ul>
        </div>
        <div className="roadmap-phase">
          <h2 className="phase-title">PHASE 4</h2>
          <h3 className="phase-time">Sustainability and Continuous Innovation (Q2 2025 and beyond)</h3>
          <ul className="phase-goals">
            <li><strong>Sustainability Initiatives:</strong> Implement sustainable technologies and practices within our operations.</li>
            <li><strong>Ongoing Feature Upgrades:</strong> Continuously upgrade the platform and mobile app based on user feedback and emerging tech trends.</li>
            <li><strong>Leadership in Asset Management Tech:</strong> Establish the platform and mobile app as leading tools in the tech-driven asset management industry.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Roadmap;

import React from 'react';
import Hero from '../sections/Hero';
import SectionOne from '../sections/SectionOne';
import SectionTwo from '../sections/SectionTwo';
import SectionThree from '../sections/SectionThree';
import Roadmap from '../sections/Roadmap';
import IntroSection from '../sections/IntroSection';
import LearnMore from '../sections/LearnMore';
import VideoAurea from '../sections/VideoAurea';

const Home = () => {
  return (
    <main>
      <Hero />
      <SectionOne />
      <VideoAurea />
      <SectionTwo />
      <SectionThree />
      <Roadmap />
      <IntroSection />
      <LearnMore />
    </main>
  );
};

export default Home;
import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './Header.css';
import logo from '../../assets/icons/imagotipo.svg';
import linkedinIcon from '../../assets/icons/Linked.svg';
import facebookIcon from '../../assets/icons/facebook.svg';
import instagramIcon from '../../assets/icons/instagram.svg';
import twitterIcon from '../../assets/icons/twitter.svg';
import hamburguerIcon from '../../assets/icons/hamburguer-aurea.svg';
import icon from '../../assets/icons/AUREA-ICON.svg';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { useGSAP } from '@gsap/react';

gsap.registerPlugin(ScrollToPlugin);

const smoothScrollTo = (id) => {
  const element = document.querySelector(id);
  if (element) {
    gsap.to(window, {
      duration: 2, // Duración en segundos, ajusta este valor para hacer el scroll más lento o más rápido
      scrollTo: {
        y: element,
        offsetY: 70 // Ajusta este valor si tienes un header fijo
      },
      ease: "power2.inOut" // Puedes cambiar la función de easing para ajustar la curva de animación
    });
  }
};

const Header = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const headerRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  useGSAP(() => {
    // Configuraciones GSAP adicionales si son necesarias
  }, { scope: headerRef });

  useEffect(() => {
    // Verifica si hay un hash en la URL después de la navegación
    if (location.hash) {
      // Espera un momento para asegurarse de que el DOM se ha actualizado
      setTimeout(() => {
        smoothScrollTo(location.hash);
      }, 0);
    }
  }, [location]);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleNavClick = (id) => {
    if (location.pathname !== '/') {
      navigate('/');
      // Espera a que la navegación se complete antes de hacer scroll
      setTimeout(() => smoothScrollTo(id), 100);
    } else {
      smoothScrollTo(id);
    }
  };

  return (
    <header ref={headerRef}>
      <DesktopHeader handleNavClick={handleNavClick} />
      <MobileHeader isModalOpen={isModalOpen} toggleModal={toggleModal} handleNavClick={handleNavClick} />
    </header>
  );
};

const DesktopHeader = ({ handleNavClick }) => {
  return (
    <header className="desktop-header">
      <div className='max-w-1200'>
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="Logo" className="logo-image" />
          </Link>
        </div>
        <nav className="desktop-nav">
          <Link className="nav-link" to="/">
            <p>The AUREA Solution</p>
          </Link>
          <a className="nav-link" href="#join-us" onClick={(e) => { e.preventDefault(); handleNavClick('#join-us'); }}>
            <p>Join Us</p>
          </a>
          <a className="nav-link" href="#faqs" onClick={(e) => { e.preventDefault(); handleNavClick('#faqs'); }}>
            <p>FAQs</p>
          </a>
          <a className="nav-link" href="#first-contact" onClick={(e) => { e.preventDefault(); handleNavClick('#first-contact'); }}>
            <p>Contact</p>
          </a>
        </nav>
        <div className="social-links">
          <a href="https://linkedin.com" className="social-link"><img src={linkedinIcon} alt="Linkedin" /></a>
          <a href="https://facebook.com" className="social-link"><img src={facebookIcon} alt="Facebook" /></a>
          <a href="https://twitter.com" className="social-link"><img src={twitterIcon} alt="Twitter" className="margin-icon" /></a>
          <a href="https://instagram.com" className="social-link"><img src={instagramIcon} alt="Instagram" className="margin-icon"/></a>
        </div>
      </div>
      <div className="divisor"></div>
    </header>
  );
};

const MobileHeader = ({ isModalOpen, toggleModal, handleNavClick }) => {
  if (isModalOpen) {
    document.body.style.overflowY = 'hidden';
  } else {
    document.body.style.overflowY = 'unset';
  }

  return (
    <>
      <header className="mobile-header">
        <Link to="/" className='logo-mobile'>
            <img src={icon} alt="logo-mobile"/>
        </Link>
        <button className="hamburger-button" onClick={toggleModal}>
            <img src={hamburguerIcon} alt="aurea-icon" />
        </button>
      </header>

      {isModalOpen && (
        <div className="modal-header">
          <button className="close-button" onClick={toggleModal}>
            X
          </button>
          <nav className="modal-nav">
            <Link className="nav-link" to="/" onClick={toggleModal}>The AUREA Solution</Link>
            <a className="nav-link" href="#join-us" onClick={(e) => { e.preventDefault(); handleNavClick('#join-us'); toggleModal(); }}>Join Us</a>
            <a className="nav-link" href="#faqs" onClick={(e) => { e.preventDefault(); handleNavClick('#faqs'); toggleModal(); }}>FAQs</a>
            <a className="nav-link" href="#first-contact" onClick={(e) => { e.preventDefault(); handleNavClick('#first-contact'); toggleModal(); }}>Contact</a>
          </nav>
          <div className="social-links">
            <a href="https://linkedin.com" className="social-link"><img src={linkedinIcon} alt="Linkedin" /></a>
            <a href="https://facebook.com" className="social-link"><img src={facebookIcon} alt="Facebook" /></a>
            <a href="https://twitter.com" className="social-link"><img src={twitterIcon} alt="Twitter" /></a>
            <a href="https://instagram.com" className="social-link"><img src={instagramIcon} alt="Instagram" /></a>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;

import React from 'react';
import './Footer.css';
import logo from '../../assets/icons/Imagotipo_x5F_horizontal_1_.svg'; // Import the logo
import linkedinIcon from '../../assets/icons/Linked.svg';
import facebookIcon from '../../assets/icons/facebook.svg';
import instagramIcon from '../../assets/icons/instagram.svg';
import twitterIcon from '../../assets/icons/twitter.svg';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="max-w-1200">
        <div className="footer-content">
            <img src={logo} alt="Aurea Logo" className="footer-logo" />
          <nav className="footer-nav">
            <a href="/terms" className="footer-link">Terms and Conditions</a>
            <a href="/privacy" className="footer-link">Privacy Policy</a>
            <a href="/contact" className="footer-link">Contact Us</a>
          </nav>
        </div>
        <div className="footer-bottom">
          <h2>© 2024 Aurea. All rights reserved.</h2>
          <nav className="footer-right">
            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" className="social-icon">
              <img src={linkedinIcon} alt="LinkedIn" />
            </a>
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" className="social-icon">
              <img src={facebookIcon} alt="Facebook" />
            </a>
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" className="social-icon">
              <img src={instagramIcon} alt="Instagram" className="margin-icon" />
            </a>
            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" className="social-icon">
              <img src={twitterIcon} alt="Twitter" className="margin-icon" />
            </a>
          </nav>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
